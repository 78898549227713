import { useAccountContext } from '@ovotech/ui-tools';
import { useDirectDebitQuery } from '@/src/api';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';
import { PostalAddress } from '@ovotech/energy-cx/dist/shared/api/kapi/v1/__generated__/kapi';

const makeDirectDebitEnd = (dayOfMonth: number): string => {
  // TODO: Add logic to handle direct debit processing time.
  const todayDate = new Date();
  const thisDay = todayDate.getDate();
  const thisMonth = todayDate.getMonth();
  const thisYear = todayDate.getFullYear();

  let endMonth: number = thisMonth;

  if (thisDay <= dayOfMonth) {
    endMonth = thisMonth + 1;
  }

  const endYear = thisYear + 1;
  const endMonthStr = new Date(endYear, endMonth, dayOfMonth).toLocaleString(
    'default',
    { month: 'long' },
  );

  return `last charge on ${dayOfMonth} ${endMonthStr} ${endYear}`;
};

const makePrices = (priceStr: string): [string, string] => {
  const price = parseInt(priceStr, 10);
  const newPrice = price + 15;

  return [`£${price}`, `£${newPrice}`];
};

export function usePersonalInfo() {
  const { accountId } = useAccountContext();
  const directDebit = useDirectDebitQuery(accountId);

  const profile = useProfileQueryKapi();

  const isFetching = directDebit.isFetching || profile.isFetching;
  const {
    givenName,
    familyName,
    contactAddress,
    contactPhoneNumbers,
    contactEmail,
  } = profile.data?.customer_nextV1 || {};

  const userName = profile.data ? `${givenName} ${familyName}` : '';
  const userAddress: PostalAddress | undefined = contactAddress?.postalAddress;
  const userPhone =
    profile.data && contactPhoneNumbers ? contactPhoneNumbers[0] : '';
  const userEmail = profile.data ? contactEmail : '';

  const directDebitEnd =
    directDebit.data && directDebit.data.dayOfMonth
      ? makeDirectDebitEnd(directDebit.data.dayOfMonth)
      : '';
  const [currentPrice, newPrice] =
    directDebit.data && directDebit.data.amount
      ? makePrices(directDebit.data.amount?.amount)
      : ['', ''];

  return {
    accountId,
    isFetching,
    userName,
    userAddress,
    userPhone,
    userEmail,
    directDebitEnd,
    currentPrice,
    newPrice,
  };
}
