import styled from 'styled-components';
import { Card as CardComponent, Heading1 } from '@ovotech/nebula';
import {
  mqMediumUp,
  mqSmallUp,
  mqMediumDown,
  mqMediumToLarge,
} from '@/src/utils/mediaQuery';
export const Card = styled(CardComponent)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;
export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledHeading1 = styled(Heading1)`
  color: white;
  align-self: center;
`;

export const CardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 24px;

  ${mqMediumToLarge(`
    grid-template-columns: 1fr 1fr;
    `)}

  ${mqMediumDown(`
    grid-template-columns: 1fr;
    `)}


  svg {
    height: 116px;
  }
`;

export const StyledContainerWithLongHeaderBg = styled.div`
  ${mqMediumUp(`
      .bg-ovo {
        height: 300px;
      }
    `)}
  .bg-ovo {
    height: 450px;
  }
`;

export const StyledImage = styled.img`
  display: none;

  ${mqSmallUp(`
    display: block;
    height: 188px;
  `)}
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  margin-top: 32px;

  ${mqSmallUp(`
    justify-content: space-between;
    flex-direction: row;
  `)};
`;

export const Container = styled.div`
  display: flex;

  background-color: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  justify-content: center;
  padding: 0px 32px;
`;

export const Content = styled.div`
  max-width: 957px;
  padding: 32px 0 68px;
`;
