import React from 'react';
import { Route } from 'react-router-dom-v5';
import { Checkout } from './pages/Checkout';
import { Compatibility } from './pages/Compatibility';
import { CompatibilityV2 } from './pages/CompatibilityV2/CompatibilityV2';
import { OfferThermostatsPage } from './pages/OfferThermostatsPage';
import { Success } from './pages/Success';
import { Error } from './pages/Error';
import { SwitchWithNotFound } from '@/src/components';
import { ROUTE_SMART_THERMOSTATS_OFFER } from '@/src/constants/routes';
import { useDecision } from '@optimizely/react-sdk';

export const SmartThermostatsOffer = () => {
  const [{ enabled, variationKey }] = useDecision(
    'smart_thermostats_compatibility_page_improvements',
  );

  return (
    <SwitchWithNotFound>
      <Route
        exact
        path={ROUTE_SMART_THERMOSTATS_OFFER}
        component={OfferThermostatsPage}
      />
      <Route
        exact
        path={ROUTE_SMART_THERMOSTATS_OFFER + '/checkout'}
        component={Checkout}
      />
      <Route
        exact
        path={ROUTE_SMART_THERMOSTATS_OFFER + '/checkout/success'}
        component={Success}
      />
      <Route
        exact
        path={ROUTE_SMART_THERMOSTATS_OFFER + '/checkout/error'}
        component={Error}
      />
      <Route
        exact
        path={ROUTE_SMART_THERMOSTATS_OFFER + '/compatibility'}
        component={
          enabled && variationKey === 'on' ? CompatibilityV2 : Compatibility
        }
      />
    </SwitchWithNotFound>
  );
};
