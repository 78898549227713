import {
  Card,
  Col,
  Heading2,
  Heading3,
  Heading4,
  Margin,
  P,
  Row,
  SkeletonCTA,
  SkeletonHeading,
  SkeletonText,
  TextLink,
} from '@ovotech/nebula';
import React from 'react';

import { useHistory } from 'react-router-dom-v5';
import { CutWasteCard } from '../components/CutWasteCard/CutWasteCard';
import { FAQSection } from '../components/FAQSection';
import { FeaturesCard } from '../components/FeaturesCard/FeaturesCard';
import { PracticalStepsCard } from '../components/PracticalStepsCard/PracticalStepsCard';
import { SmallText } from '../components/ProductContent.styled';
import { ProductHero } from '../components/ProductHero';
import {
  GREEN_FRIDAY_LINK,
  SMART_THERM_TERMS_AND_CONDITIONS_LINK,
} from '../constants';
import { useTadoEligible } from '../hooks/use-tado-eligible';
import { useTadoPricingQuery } from '../hooks/use-tado-pricing';
import { OrderType, PaymentType } from '../utils/types';
import { buildPromoCodeLink, hasBlackFridayDiscount } from '../utils/utils';
import { MaintenancePage } from './MaintenancePage';
import {
  ErrorBoundary,
  ErrorSection,
  FootnoteProvider,
  FootnoteYield,
  PageWithNavigation,
} from '@/src/components';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { SMART_THERMOSTATS_PRODUCTS } from '@/src/constants/analytics-subjects';
import { SMART_THERMOSTATS_MAINTENANCE } from '@/src/constants/feature-flags';
import { ROUTE_SMART_THERMOSTATS_OFFER } from '@/src/constants/routes';
import { useFeature } from '@/src/utils/optimizely';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { AnalyticsClick } from '@ovotech/ui-tools';
import useWindowDimensions from '@/src/utils/hooks/useWindowDimensions';

export const OfferThermostatsPage = () => {
  const history = useHistory();
  const { isMobile } = useWindowDimensions();

  const { isEligible, isError } = useTadoEligible();
  const promoCode = getQueryParamValue('promoCode');
  const pricingQuery = useTadoPricingQuery(promoCode);
  const hasDiscount =
    !!pricingQuery.data?.[PaymentType.FullAmountPayment][OrderType.SelfInstall]
      .discountedTotal;
  const blackFridayDiscountApplied = hasBlackFridayDiscount(
    hasDiscount,
    promoCode ?? '',
  );

  const [isUnderMaintenance] = useFeature(SMART_THERMOSTATS_MAINTENANCE);

  const goToCompatibilityStep = () => {
    const route = buildPromoCodeLink(
      ROUTE_SMART_THERMOSTATS_OFFER + '/compatibility',
      promoCode,
    );
    history.push(route);
  };

  const ErrorCard = () => (
    <Card>
      <ErrorSection
        headingComponent={Heading3}
        id={'smart-thermostats-status-error'}
      />
    </Card>
  );

  if (pricingQuery.isError) {
    return (
      <PageWithNavigation title="Smart Thermostats">
        <Margin top={8}>
          <ErrorCard />
        </Margin>
      </PageWithNavigation>
    );
  }

  if (pricingQuery.isLoading || pricingQuery.data === undefined) {
    return (
      <PageWithNavigation title="Smart Thermostats">
        <Margin top={8}>
          <Card>
            <SkeletonHeading size="100%" />
          </Card>
          <br />
          <Card>
            <Row isNested>
              <Col medium={6}>
                <SkeletonHeading size="100%" headingComponent={Heading2} />
                <SkeletonText lines={2} />
                <SkeletonHeading size="100%" headingComponent={Heading3} />
                <SkeletonText lines={3} />
                <SkeletonCTA />
              </Col>
              <Col medium={6}>
                <SkeletonHeading size="100%" headingComponent={Heading2} />
                <SkeletonText lines={2} />
                <SkeletonHeading size="100%" headingComponent={Heading3} />
                <SkeletonText lines={3} />
                <SkeletonCTA />
              </Col>
            </Row>
          </Card>
        </Margin>
      </PageWithNavigation>
    );
  }

  // TODO: move every imported component from V1 to V2
  if (!isUnderMaintenance) {
    return (
      <PageWithNavigation
        analytics={{ name: SMART_THERMOSTATS_PRODUCTS }}
        title="Smart Thermostats"
      >
        <FootnoteProvider>
          <ErrorBoundary errorId={'smart-thermostats-page-error-boundary'}>
            <Row isNested>
              <Col>
                <Margin top={4}>
                  {isError ? (
                    <ErrorCard />
                  ) : pricingQuery.data ? (
                    <ProductHero
                      eligible={isEligible}
                      goToCompatibilityStep={goToCompatibilityStep}
                      pricing={pricingQuery.data}
                      hasDiscount={hasDiscount}
                      promoCode={promoCode ?? ''}
                    />
                  ) : (
                    <SkeletonCard />
                  )}
                </Margin>
              </Col>
            </Row>
            <Margin top={isMobile ? 6 : 8}>
              <FeaturesCard />
            </Margin>
            <Margin top={6}>
              {isError ? (
                <ErrorCard />
              ) : pricingQuery.data ? (
                <PracticalStepsCard pricing={pricingQuery.data} />
              ) : (
                <SkeletonCard />
              )}
            </Margin>
            <Margin top={6}>
              <CutWasteCard />
            </Margin>
            <Margin top={isMobile ? 12 : 30}>
              <FAQSection isMobile={isMobile} />
            </Margin>
            <Margin top={isMobile ? 12 : 24}>
              <Heading4>Important information</Heading4>
              <FootnoteYield
                customFootnoteWrapperStyle={{ marginBottom: 12 }}
                heading={
                  <>
                    {' '}
                    {blackFridayDiscountApplied && (
                      <P as={SmallText}>
                        Offer terms apply, see{' '}
                        <TextLink href={GREEN_FRIDAY_LINK}>here</TextLink>
                        for details.
                      </P>
                    )}
                    <P as={SmallText}>
                      View our full{' '}
                      <AnalyticsClick
                        name={
                          SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE
                            .CLICKED.TERMS_AND_CONDITIONS
                        }
                      >
                        <TextLink href={SMART_THERM_TERMS_AND_CONDITIONS_LINK}>
                          Terms and Conditions
                        </TextLink>
                      </AnalyticsClick>
                    </P>
                  </>
                }
              />
            </Margin>
          </ErrorBoundary>
        </FootnoteProvider>
      </PageWithNavigation>
    );
  }
  return <MaintenancePage />;
};
