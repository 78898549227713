export const EV_ANYTIME_SMART_CHARGER_LINK =
  'https://www.ovoenergy.com/electric-cars/smart-charger';

// If you need to update SmartChargers remember to update the enum in the backend
// https://github.com/ovotech/charge-anytime-eligibility-backend/blob/main/src/types.ts
export enum SmartChargers {
  Indra = 'Indra',
  Ohme = 'Ohme',
  Hypervolt = 'Hypervolt',
  Andersen = 'Andersen',
  ThreePin = 'ThreePin',
  NotListed = 'NotListed',
}

export const smartChargersOptions = [
  {
    value: SmartChargers.Indra,
    text: 'Indra: Smart PRO/Smart Charger V3',
  },
  {
    value: SmartChargers.Ohme,
    text: 'Ohme: Home/Home Pro/ePod',
  },
  {
    value: SmartChargers.Hypervolt,
    text: 'Hypervolt: Home 3.0/Home 3 Pro',
  },
  {
    value: SmartChargers.Andersen,
    text: 'Andersen: A2 (2024)/A3',
  },
  {
    value: SmartChargers.ThreePin,
    text: 'I use a standard 3 pin plug',
  },
  {
    value: SmartChargers.NotListed,
    text: 'My charger isn’t listed',
  },
];

export const smartChargerSelections = (exclusions: Array<SmartChargers>) =>
  smartChargersOptions.filter((i) => !exclusions.includes(i.value));
