import {
  RandomTipsAllCategories,
  AnyBreakdownCatID,
} from '../pages/EnergyInsights/types';
import { Fuel } from '@/src/types/Response';

export const ANALYTICS_ACCOUNT_ID = 'Account Id';
export const ANALYTICS_CUSTOMER_ID = 'Customer Id';
export const CLICKED_GREENLIGHT_LINK = 'PAYM - Clicked Greenlight banner';
export const VIEWED_GREENLIGHT_LINK = 'PAYM - Viewed Greenlight banner';
export const APPLIED_FOR_A_REFUND = 'PAYM - Applied For A Refund Screen';
export const CLICKED_OFFER_ON_OFFERS_PAGE =
  'PAYM - Clicked offer on Offers page';

export const CLICKED_OFFER_EVENT = 'PAYM - Clicked Offer';
export const VIEWED_OFFER_EVENT = 'PAYM - Viewed Offer';

export const VIEWED_OFFERS_SCREEN = 'PAYM - Viewed Offers Screen';
export const CLICKED_CANCEL_ADDON = 'PAYM - Profile addon cancelled';
export const VIEWED_OPENING_METER_READINGS_INPUT_SCREEN =
  'PAYM - Viewed Opening Meter Reading Input Screen';
export const UPDATED_MONTHLY_DIRECT_DEBIT =
  'PAYM - Updated Monthly Direct Debit';
export const UPDATED_MONTHLY_DIRECT_DEBIT_ERROR =
  'PAYM - Updated Monthly Direct Debit Error';
export const SUBMITTED_OPENING_METER_READING =
  'PAYM - Submitted Opening Meter Reading';
export const SUBMITTED_OPENING_METER_READING_ERROR =
  'PAYM - Submitted Opening Meter Reading Error';
export const VIEWED_ENERGY_ACCOUNT_BALANCE =
  'PAYM - Viewed Energy Account Balance';
export const VIEWED_PAYMENTS_SCREEN_ERROR =
  'PAYM - Viewed Payments Screen Error';
export const DIRECT_DEBIT_SETUP_VIEWED_CONFIRMATION_STEP =
  'PAYM - Direct Debit Setup Viewed Confirmation Step';
export const BANNER_CTA_CLICKED = 'Action Centre: Banner CTA clicked';
export const NOT_REQUESTED_SWITCH_CTA_CLICKED =
  'Loss: Not requested this switch? CTA clicked';
export const GET_A_QUOTE_CTA_CLICKED = 'Loss: get a quote? CTA clicked';
export const MAKE_A_PAYMENT_CTA_CLICKED =
  'Loss: make a one time payment? CTA clicked';
export const LOSS_MESSAGE_SHOWN = 'Loss message shown';
export const CLICKED_OVO_GREENLIGHT_NAV = 'PAYM - Clicked OVO Greenlight nav';
export const OVO_GREENLIGHT_NAV_ERROR = 'PAYM - OVO Greenlight nav error';
export const CLICKED_GREENLIGHT_FROM_BANNER =
  'PAYM - Home (Energy) - Clicked navigate to Greenlight from banner';
export const VIEWED_GREENLIGHT_BANNER =
  'PAYM - Home (Energy) - Viewed Greenlight banner';
export const FUNCTIONAL_SUPPORT_LINK_CLICKED =
  'Orion Web - direct debit update - clicked financial support link';
export const CLICKED_OVO_FOUNDATION_DONATE_AMOUNT =
  'Clicked OVO Foundation amount button';
export const CLICKED_OVO_FOUNDATION_DONATE_SUBMIT =
  'Clicked OVO Foundation submit button';
export const CLICKED_OVO_GREENLIGHT_FROM_USAGE =
  'Orion Web - Usage Page - Clicked Navigate to OVO Greenlight';
export const CLICKED_USAGE_IN_NAV =
  'Orion Web - Navigation - Clicked Navigate to Usage';
export const CLICKED_SMB_IN_NAV =
  'Orion Web - Navigation - Clicked Navigate to Smart Meter Booking';

export const ORION_WELCOME_MODAL_INTRO_EXIT_CLICKED =
  'Orion Welcome Modal Intro exit clicked';
export const ORION_WELCOME_MODAL_INTRO_SHOWN =
  'Orion Welcome Modal Intro shown';
export const ORION_WELCOME_MODAL_INTRO_NEXT_CLICKED =
  'Orion Welcome Modal Intro next clicked';
export const ORION_WELCOME_MODAL_BALANCES_EXIT_CLICKED =
  'Orion Welcome Modal Balances exit clicked';
export const ORION_WELCOME_MODAL_BALANCES_SHOWN =
  'Orion Welcome Modal Balances shown';
export const ORION_WELCOME_MODAL_BALANCES_BACK_CLICKED =
  'Orion Welcome Modal Balances back clicked';
export const ORION_WELCOME_MODAL_BALANCES_NEXT_CLICKED =
  'Orion Welcome Modal Balances next clicked';
export const ORION_WELCOME_MODAL_DIRECT_DEBIT_EXIT_CLICKED =
  'Orion Welcome Modal Direct Debit exit clicked';
export const ORION_WELCOME_MODAL_DIRECT_DEBIT_SHOWN =
  'Orion Welcome Modal Direct Debit shown';
export const ORION_WELCOME_MODAL_DIRECT_DEBIT_BACK_CLICKED =
  'Orion Welcome Modal Direct Debit back clicked';
export const ORION_WELCOME_MODAL_DIRECT_DEBIT_NEXT_CLICKED =
  'Orion Welcome Modal Direct Debit next clicked';
export const ORION_WELCOME_MODAL_QUESTIONS_EXIT_CLICKED =
  'Orion Welcome Modal Questions exit clicked';
export const ORION_WELCOME_MODAL_QUESTIONS_SHOWN =
  'Orion Welcome Modal Questions shown';
export const ORION_WELCOME_MODAL_QUESTIONS_TELL_ME_EVERYTHING_CLICKED =
  'Orion Welcome Modal Questions tell me everything clicked';
export const ORION_WELCOME_MODAL_QUESTIONS_EXPLORE_MY_ACCOUNT_CLICKED =
  'Orion Welcome Modal Questions explore my account clicked';

export const COMPLETED_RENEWAL = 'REN - Completed Renewal / Refix';

export const SMB_NAV_BOOKING_CLICKED = 'SMB - Navigation - Booking Clicked';
export const SMB_NAV_BOOKING_VIEWED = 'SMB - Navigation - Booking Viewed';

export const RENDER_DD_WIDGET = 'Orion Web - DD Widget rendered';

export const SMART_NONCOMM_HELP =
  'Orion Web - Smart Non-Comm Banner - clicked help link';

export const METER_READINGS = {
  FORM_PAGE: {
    FORM_ERROR: 'PAYM - Meter Reading Input Screen form error',
  },
  ACTIONS: {
    SUBMIT_SUCCESS:
      'Orion Web - Meter readings - Successful meter reading submission',
    SUBMIT_ERROR: 'Orion Web - Meter readings - meter reading submission error',
  },
};

export const HOME = {
  MANAGE_YOUR_PAYMENTS: 'Orion Web - Home - Selected Manage your payments', //
  UNDERSTANDING_YOUR_BALANCE:
    'Orion Web - Home - Selected Understanding your balance', //
  CHANGE_DD_AMOUNT: 'Orion Web - Home - Selected Change Direct Debit amount',
  SEE_HOW_BILLING_WORKS: 'Orion Web - Home - Selected See how billing works', //
  CURRENT_BILLING_PERIOD: 'Orion Web - Home - Selected Current billing period',
  DOWNLOAD_YOUR_BILLS: 'Orion Web - Home - Selected Download your bills', //
  PREVIOUS_BILLS: 'Orion Web - Home - Selected Previous bills',
  SHOW_MORE_BILLING_PERIOD:
    'Orion Web - Home - Selected Show more billing period',
  CARBON_TRACKER: 'Orion Web - Home - Selected Carbon tracker',
  LAST_PERIOD: {
    CLICKED_VIEW_DETAILS: 'Orion Web - Home - Clicked Last Period view details',
    CLICKED_VIEW_ALL_BILLS:
      'Orion Web - Home - Clicked view and download all bills',
  },
  CURRENT_PERIOD: {
    CLICKED_VIEW_DETAILS:
      'Orion Web - Home - Clicked Current Period view details',
  },
  BILLING: {
    LATEST: 'Orion Web - Home - Latest billing period rendered',
    HISTORICAL: 'Orion Web - Home - Last complete billing period rendered',
  },
};

export const MENU = {
  USAGE: 'Orion Web - Menu - Selected Usage',
  METER_READINGS: 'Orion Web - Menu - Selected Meter readings',
  BILLING_HISTORY: 'Orion Web - Menu - Selected Billing History',
  PAYMENTS: 'Orion Web - Menu - Selected Payments',
  PLAN_DETAILS: 'Orion Web - Menu - Selected Plan details',
  OPEN_PLAN_DROPDOWN: 'Orion Web - Menu - Opened Plan dropdown',
  GREENER_ENERGY: 'Orion Web - Menu - Selected Greener Energy',
  OVO_GREENLIGHT: 'Orion Web - Menu - Selected OVO Greenlight',
  OFFERS: 'Orion Web - Menu - Selected Offers',
  MOVING_HOME: 'Orion Web - Menu - Selected Moving home',
  HELP_AND_SUPPORT: 'Orion Web - Menu - Selected Help and support',
  PROFILE: 'Orion Web - Menu - Selected Profile',
  LOGOUT: 'Orion Web - Menu - Selected Logout',
  PATH_TO_ZERO: 'Orion Web - Menu - Selected Path To Zero',
  POWER_MOVE: 'Orion Web - Menu - Selected Power Move',
};

export const PAYMENTS = {
  CHANGE_DD_AMOUNT:
    'Orion Web - Payments - Selected Change Direct Debit amount',
  DD_AMOUNT_CHANGE_ERROR:
    'Orion Web - Payments - Direct Debit amount change error',
  DD_GUARANTEE: 'Orion Web - Payments - Selected Direct Debit Guarantee',
  CHANGE_DD_DATE: 'Orion Web - Payments - Selected Change Direct Debit date',
  DD_DATE_CHANGE_SUCCESSFUL:
    'Orion Web - Payments - Successful Direct Debit date change',
  DD_NOT_ACCT_HOLDER:
    'Orion Web - Payments - Toggled "Not account holder" explainer',
  GO_TO_DD_CALCULATOR:
    'Orion Web - Payments - Selected Go to Direct Debit calculator',
  MAKE_TOP_UP_PAYMENT: 'Orion Web - Payments - Selected Make a top-up payment',
  MAKE_PAYMENT: 'Orion Web - Payments - Selected Make a payment',
  TOP_UP_SUBMITTED: 'Orion Web - Payments - Top-up submitted',
  TOP_UP_CANCELLED: 'Orion Web - Payments - Top-up cancelled',
  TOP_UP_AMOUNT_VALIDATION_FAIL:
    'Orion Web - Payments - Top-up amount validation failure',
  FIND_OUT_HOW_TO_GET_REFUND:
    'Orion Web - Payments - Selected Find out how to get a refund',
  DOWNLOAD_BILL: 'Orion Web - Payments - Download bills',
  KEEP_TRACK_YOUR_PAYMENTS:
    'Orion Web - Payments - Selected Keep track of your payments',
  TOGGLED_STRUGGLING_TO_PAY:
    'Orion Web - DD Widget - Toggled Struggling To Pay',
  GET_SUPPORT_CTA_VIEWED: 'GetSupportCTA',
  GET_SUPPORT_CTA_CLICKED: 'LearnMoreCTA',
};

export const REFUNDS_HOW_TO = {
  CLICKED_APPLY_FOR_REFUND:
    'Orion Web - Refunds How To - Clicked Apply For Refund Button',
};

export const REFUNDS_INELIGIBLE = {
  CLICKED_REFUND_POLICY:
    'Orion Web - Refund Not Eligible - Clicked Refund Policy Link',
  CLICKED_SETUP_DD_LINK:
    'Orion Web - Refund Not Eligible - Clicked Set Up DD Link',
};

export const REFUNDS = {
  CLICKED_CANCEL_BUTTON: 'Orion Web - Refunds - Clicked Cancel Button',
  CLICKED_APPLY_FOR_A_REFUND_BUTTON:
    'Orion Web - Refunds - Clicked Apply For A Refund Button',
  REFUND_REQUEST_SUCCESSFUL: 'Orion Web - Payments - Successful refund request',
  REFUND_REQUEST_ERROR: 'Orion Web - Payments - Refund request error',
  REFUND_REQUEST_INELEGIBLE: 'Orion Web - Payments - Ineligible refund request',
};

export const DIRECT_DEBIT_CALCULATOR = {
  ENTERED_DIRECT_DEBIT_AMOUNT:
    'Orion Web - Direct Debit Calculator - Entered Amount Into Direct Debit Field',
  CLICKED_CHANGE_DIRECT_DEBIT_AMOUNT:
    'Orion Web - Direct Debit Calculator - Selected Change Direct Debit Amount Page Link',
  MONTHLY_SHOW_COST_RADIO:
    'Orion Web - Direct Debit Calculator - Selected Show Cost',
  MONTHLY_SHOW_BALANCE_RADIO:
    'Orion Web - Direct Debit Calculator - Selected Show Balance',
  FORECAST_END_OF_CONTRACT:
    'Orion Web - Direct Debit Calculator - Selected Forecast Until End Of Contract',
  FORECAST_NEXT_TWELVE_MONTHS:
    'Orion Web - Direct Debit Calculator - Selected Forecast Next Twelve Months',
};

export const CHANGE_DD_DATE = {
  SELECTED_VALUE_FROM_DROPDOWN:
    'Orion Web - Change DD Date - Selected Value From Dropdown',
};

export const BILLING = {
  CLICKED_VIEW_METER_READINGS: 'Orion Web - Billing - View meter readings',
  CLICKED_SEND_METER_READING:
    'Orion Web - Billing - Selected send your reading',
  CLICKED_LOAD_MORE: 'Orion Web - Billing History - Clicked load more',
  CLICKED_VIEW_DETAILS: 'Orion Web - Billing History - Clicked view details',
  CLICKED_DOWNLOAD_THIS_BILL:
    'Orion Web - Billing History - Clicked download this bill',
  CLICKED_LAST_UPDATED_BADGE:
    'Orion Web - Billing - Clicked last updated badge',
  CLICKED_VIEW_CURRENT_BILLING_PERIOD:
    'Orion Web - Billing - Clicked view current billing period',
  VIEWED_BILLING_HISTORY_PAGE:
    'Orion Web - Billing History - Viewed billing history page',
  VIEWED_BILLING_DETAILS_PAGE:
    'Orion Web - Billing History - Viewed billing details page',
};

export const SMART_THERMOSTATS = {
  OFFER_BANNER: {
    VIEWED: 'THERM - Banner shown',
    CLICKED: 'THERM - Banner clicked',
  },
  PRODUCT_PAGE: {
    VIEWED: 'THERM - Viewed product page',
    SHOWN: {
      GET_TADO: 'THERM - Shown get tado button',
      WAITLIST: 'THERM - Shown waitlist button',
    },
    CLICKED: {
      GET_TADO: 'THERM - Clicked get tado',
    },
  },
  QUESTIONS_PAGE: {
    BOILER_TYPE: {
      VIEWED: 'THERM - Viewed boiler type',
      CLICKED: {
        BACK: 'THERM - Clicked back - boiler type',
        CONTINUE: 'THERM - Clicked continue - boiler type',
        NOT_SURE: 'THERM - Clicked not sure - boiler type',
      },
    },
    CURRENT_THERMOSTAT: {
      VIEWED: 'THERM - Viewed current thermostat',
      CLICKED: {
        BACK: 'THERM - Clicked back - current thermostat',
        CONTINUE: 'THERM - Clicked continue - current thermostat',
        FIND_OUT: 'THERM - Clicked find out - current thermostat',
        NOT_SURE: 'THERM - Clicked not sure - current thermostat',
      },
    },
    HOW_MANY_THERMOSTATS: {
      VIEWED: 'THERM - Viewed how many',
      CLICKED: {
        BACK: 'THERM - Clicked back - how many',
        CONTINUE: 'THERM - Clicked continue - how many',
        NOT_SURE: 'THERM - Clicked not sure - how many',
      },
    },
    NOT_SURE: {
      VIEWED: 'THERM - Viewed not sure',
      CLICKED: {
        BACK: 'THERM - Clicked back - not sure',
        EMAIL: 'THERM - Clicked email - not sure',
        TELEPHONE: 'THERM - Clicked telephone - not sure',
      },
    },
    TADO_NO_SUPPORT: {
      VIEWED: 'THERM - Viewed tado no support ',
      CLICKED: {
        BACK: 'THERM - Clicked back - tado no support',
      },
    },
    COMING_SOON: {
      VIEWED: 'THERM - Viewed coming soon ',
      CLICKED: {
        BACK: 'THERM - Clicked back - waitlist',
        REMOVE_WAITLIST: 'THERM - Clicked remove waitlist',
        WAITLIST: 'THERM - Clicked waitlist',
      },
    },
  },
  CHECKOUT_PAGE: {
    VIEWED: 'THERM - Viewed checkout',
    CLICKED: {
      BUY: 'THERM - Clicked buy',
      LEGAL_CHECKBOX: 'THERM - Clicked legal checkbox',
      HARDSHIP_CHECKBOX: 'THERM - Clicked hardship checkbox',
      UPDATE_DETAILS: 'THERM - Clicked update details',
    },
  },
  SUCCESS_PAGE: {
    VIEWED: 'THERM - Viewed success',
    CLICKED: {
      APPLE_APP_STORE: 'THERM - Clicked app store',
      GOOGLE_APP_STORE: 'THERM - Clicked google play',
      EMAIL: 'THERM - Clicked email',
      FAQ: 'THERM - Clicked FAQs',
      TELEPHONE: 'THERM - Clicked telephone',
    },
  },
  MY_THERMOSTATS_PAGE: {
    NOT_CONNECTED: {
      VIEWED: 'THERM - Viewed My Thermostat Not Connected',
      CLICKED: {
        CONNECT: 'THERM - Clicked Tado Connect Button',
      },
    },
    SUCCESS_NOTIFICATION: {
      VIEWED: 'THERM - Viewed Success Notification',
    },
    CONNECTED: {
      VIEWED: 'THERM - Viewed My Thermostat',
      CLICKED: {
        PREV: 'THERM - Clicked My Thermostat Prev',
        NEXT: 'THERM - Clicked My Thermostat Next',
      },
    },
    PRODUCT_PAGE: {
      CLICKED: {
        BUY_NOW: 'THERM - Product page - Clicked buy now',
        COMPATIBILITY_GUIDE:
          'THERM - Product page - Clicked compatibility guide',
        INSTALL_GUIDE: 'THERM - Product page - Clicked install guide',
        FAQ: 'THERM - Product page - Clicked FAQs',
        TERMS_AND_CONDITIONS:
          'THERM - Product page - Clicked terms and conditions',
        INDEPENDENT_STUDY: 'THERM - Product page - Clicked independent study',
        HOW_TO_INSTALL: 'THERM - Product page - Clicked how to install',
        KEEP_ME_POSTED: 'THERM - Product page - Clicked keep me posted',
      },
    },
    GET_SET_FOR_TADO: {
      VIEWED: 'THERM - Viewed get set for tado - step 1',
      VIEWED_V2: 'THERM - Viewed get set for tado - step 1 - V2',
      CLICKED: {
        READY_TO_ORDER: 'THERM - Get set for Tado - Clicked ready to order',
        BACK: 'THERM - Get set for Tado - Clicked back',
        TADO_TECH_SUPPORT:
          'THERM - Get set for Tado - Clicked contact tado tech support',
      },
    },
    ORDER_YOUR_TADO: {
      VIEWED: 'THERM - Viewed order your tado - step 2',
      CLICKED: {
        EDIT_PHONE: 'THERM - Order your tado - Clicked edit phone',
        EDIT_EMAIL: 'THERM - Order your tado - Clicked edit email',
        PAYMENT_OPTION: 'THERM - Order your tado - Toggled payment option',
        TERMS_AND_CONDITIONS:
          'THERM - Order your tado - Clicked terms and conditions',
        PRIVACY_POLICY: 'THERM - Order your tado - Clicked privacy policy',
        CONFIRM_MY_ORDER: 'THERM - Order your tado - Clicked confirm my order',
        BACK: 'THERM - Order your tado - Clicked back',
        HOME: 'THERM - Order your tado - Clicked home',
      },
      ERROR: {
        PAY_NOW_BUTTON_HIDDEN:
          'THERM - Order your tado - "Pay Now" button hidden',
        PAYMENT: 'THERM - Order your tado - payment error',
        MAINTENANCE: 'THERM - Order your tado - maintenance error',
      },
    },
    GOT_YOUR_ORDER: {
      VIEWED: 'THERM - Viewed great we got your order - success',
      CLICKED: {
        BACK_TO_ACCOUNT: 'THERM - Got your order - Clicked back to account',
        INSTALLATION_GUIDE:
          'THERM - Got your order - Clicked installation guide',
        FIND_AN_INSTALLER: 'THERM - Got your order - Clicked find an installer',
        FAQ: 'THERM - Got your order - Clicked FAQs',
        TO_EMAIL: 'THERM - Got your order - Clicked to email',
        TO_CALL: 'THERM - Got your order - Clicked to call',
        GO_TO_APP_STORE: 'THERM - Got your order - Clicked go to app store',
        GO_TO_GOOGLE_PLAY: 'THERM - Got your order - Clicked go to google play',
      },
    },
  },
};

export const BOLT_ONS = {
  DISCONNECT_BOLTON_CLICKED: 'Disconnect bolton clicked',
};

export const CARBON_ZERO = {
  HOME_PROFILE_WIDGET: {
    VIEWED: 'CarbonZero - Home - Home Profile Widget - Viewed',
    CTA_CLICKED: 'CarbonZero - Home - Home Profile Widget CTA - Clicked',
    CLOSE_BUTTON_CLICKED:
      'CarbonZero - Home - Home Profile Widget - Close button - Clicked',
  },
};

export type EnergyInsightsEvent =
  (typeof ENERGY_INSIGHTS)[keyof typeof ENERGY_INSIGHTS];

export const ENERGY_INSIGHTS = {
  BUTTON_CLICK_ENERGY_SPOTLIGHTS_SMART_METER_BOOK_NOW:
    'Carbon Zero - Button Click - Energy Spotlights Demo - Smart Meter - Book Now',
  BUTTON_CLICK_ENERGY_SPOTLIGHTS_SMART_METER_BOOK_NOW_BOTTOM:
    'Carbon Zero - Button Click Bottom - Energy Spotlights Demo - Smart Meter - Book Now',
  BUTTON_CLICK_ENERGY_SPOTLIGHTS_EV_LINK:
    'Carbon Zero - Button Click - Energy Spotlights - Electric Vehicle link',
  BUTTON_CLICK_UPDATE_HOME_PROFILE_HUB:
    'Greenlight Hub - Clicked update home profile',
  BUTTON_CLICK_UPDATE_HOME_PROFILE_BREAKDOWN:
    'Greenlight Breakdown - Clicked update home profile',
  BUTTON_CLICK_HOME_PROFILE_CLOSE_BUTTON:
    'Carbon Zero - Home profile form - Clicked close button',
  BUTTON_CLICK_HOME_PROFILE_FINISH_BUTTON:
    'Carbon Zero - Carbon Dashboard - Clicked Finish updating Home profile',
  BUTTON_CLICK_HOME_PROFILE_SEE_ENERGY_SPOTLIGHTS:
    'Carbon Zero - Energy Spotlights - See your Energy Spotlights',
  BUTTON_CLICK_HOME_PROFILE_LEARN_MORE_BUTTON:
    'Carbon Zero - Carbon Dashboard - Clicked Learn more on Home profile',
  BUTTON_CLICK_GREENLIGHT_HUB_SMART_METER_BOOK:
    'Greenlight Hub - Clicked Get my smart meter',
  BUTTON_CLICK_HALF_HOURLY_OPT_IN:
    'Greenlight Hub - Clicked half hourly opt in',
  BUTTON_CLICK_HALF_HOURLY_OPT_IN_CLOSE:
    'Greenlight Hub - Clicked half hourly opt in close',
  BUTTON_CLICK_HUB_NAVIGATE_TO_BREAKDOWN:
    'Carbon Zero - Hub - Navigate to breakdown',
  BUTTON_CLICK_SMART_THERMOSTAT_TIP:
    'Greenlight Hub - Button Click - Smart Thermostat Tip - Clicked Tell me more',
  BUTTON_CLICK_HUB_NAVIGATE_TO_CARBON_INTENSITY:
    'Carbon Zero - Hub - Navigate to carbon intensity',
  BUTTON_CLICK_BREAKDOWN_HOW_CALC:
    'Carbon Zero - Breakdown - Clicked how we calculate breakdown',
  BUTTON_CLICK_SMART_THERMOSTAT_BANNER:
    'Carbon Zero - Hub - Clicked tell me more smart thermostat banner',
  BUTTON_CLICK_TIP_NOT_FOR_ME: 'Carbon Zero - Tips - Clicked not for me',
  BUTTON_CLICK_TIP_WILL_DO: 'Carbon Zero - Tips - Clicked will do',
  BUTTON_CLICK_TIP_ALREADY_DOING: 'Carbon Zero - Tips - Clicked already doing',
  BUTTON_CLICK_CHALLENGES_JOIN:
    'Carbon Zero - Challenges - Clicked join challenge',
  BUTTON_CLICK_CHALLENGES_END:
    'Carbon Zero - Challenges - Clicked end challenge',
  BUTTON_CLICK_CHALLENGES_RESTART:
    'Carbon Zero - Challenges - Clicked restart challenge',
  BUTTON_CLICK_CHALLENGES_VIEW_OTHERS:
    'Carbon Zero - Challenges - Clicked view other challenge',
  BUTTON_CLICK_EV_CALCULATOR: 'Carbon Zero - Hub - Clicked try the calculator',
  BUTTON_CLICK_CARBON_INTENSITY_HUB_HEADER_LINK:
    'Carbon Zero - Hub - Clicked header link for carbon intensity',
  CARBON_INTENSITY_SCROLL: 'Carbon Zero - Intensity - Scroll',
  SPOTLIGHT_WEEK_SCROLL: 'Carbon Zero - Spotlight - Week Scroll - Backward',
  HOMES_LIKE_YOURS_VIEWED_INTRO_PAGE:
    'Carbon Zero - Homes Like Yours - intro page viewed',
  /** Step number should be added to end */
  HOMES_LIKE_YOURS_: 'Carbon Zero - Homes Like Yours - Step - ',
  HOMES_LIKE_YOURS_0: 'Carbon Zero - Homes Like Yours - Step - 0',
  HOMES_LIKE_YOURS_1: 'Carbon Zero - Homes Like Yours - Step - 1',
  HOMES_LIKE_YOURS_2: 'Carbon Zero - Homes Like Yours - Step - 2',
  HOMES_LIKE_YOURS_3: 'Carbon Zero - Homes Like Yours - Step - 3',
  HOMES_LIKE_YOURS_4: 'Carbon Zero - Homes Like Yours - Step - 4',
  HOMES_LIKE_YOURS_5: 'Carbon Zero - Homes Like Yours - Step - 5',
  HOMES_LIKE_YOURS_6: 'Carbon Zero - Homes Like Yours - Step - 6',
  HOMES_LIKE_YOURS_7: 'Carbon Zero - Homes Like Yours - Step - 7',
  HOMES_LIKE_YOURS_8: 'Carbon Zero - Homes Like Yours - Step - 8',
  HOMES_LIKE_YOURS_9: 'Carbon Zero - Homes Like Yours - Step - 9',
  HOMES_LIKE_YOURS_10: 'Carbon Zero - Homes Like Yours - Step - 10 finish',
  NO_USAGE_BREAKDOWN_VIEW: 'Carbon Zero - No Usage Breakdown Screen - Viewed',
  NO_USAGE_BREAKDOWN_SMB_UNKNOWN_REASON:
    'Carbon Zero - No Usage Breakdown Screen - Unknown reason for smb not bookable',
  NO_USAGE_BREAKDOWN_SMB_NOT_AVAILABLE:
    'Carbon Zero - No Usage Breakdown Screen - Smb not available',
  NO_USAGE_BREAKDOWN_INELIGIBLE_FOR_SMART_METER:
    'Carbon Zero - No Usage Breakdown Screen - Ineligible for Smart Meter',
  HALF_HOURLY_OPT_IN_SUCCESS: 'Greenlight Hub - Half hourly opt in success',
  HALF_HOURLY_OPT_IN_ERROR: 'Greenlight Hub - Half hourly opt in error',
  HOME_PROFILE_PROMPT_DISMISSED_HUB:
    'Greenlight Hub - Home profile prompt dismissed',
  HOME_PROFILE_PROMPT_DISMISSED_BREAKDOWN:
    'Greenlight Breakdown - Home profile prompt dismissed',
  VIEWED_CHALLENGES_WIDGET: 'Carbon Zero - Hub - Viewed challenges widget',
  VIEWED_NO_BREAKDOWN_MESSAGE:
    'Carbon Zero - Breakdown - Viewed no breakdown message',
  CLICKED_NO_BREAKDOWN_BACK_TO_HUB:
    'Carbon Zero - Breakdown - No breakdown clicked back to hub',

  spotlightsClickedShiftLeft: (
    category: AnyBreakdownCatID | RandomTipsAllCategories,
  ) => `Carbon Zero - clicked ${category} left arrow`,
  spotlightsClickedShiftRight: (
    category: AnyBreakdownCatID | RandomTipsAllCategories,
  ) => `Carbon Zero - clicked ${category} right arrow`,
  usageBreakdownLearnMore: (category: AnyBreakdownCatID) =>
    `Carbon Zero - clicked learn more for category: ${category}`,
  spotlightsFuelTabChange: (fuel: Fuel) =>
    `Carbon zero - spotlights - opened ${fuel} tab`,
  spotlightsExpandedCategory: (category: AnyBreakdownCatID) =>
    `Carbon zero - spotlights - expanded ${category}`,
} as const;

export const NEW_OFFERS_PAGE = {
  BEFORE_YOU_GET_STARTED_BANNER_ID: 'smartmetercheck',
  ENERGY_EXPERT_ID: 'energyexpert',
  BOILER_SERVICE_ID: 'boilerservice',
  FUTURE_OF_GREEN_POWER_ID: 'futureofgreenpower',
};

export const NBA_HIGH_BALANCE = {
  NBA_HIGH_BALANCE_BANNER: 'NBA - Balance review card - presented',
  NBA_HIGH_BALANCE_MODAL: 'NBA - Balance review card - clicked Learn more',
};

export const CUSTOMER_ACTION_CARD_EVENTS = {
  cardShown: 'CAC - Showed card',
  cardTapped: 'CAC - Tapped card',
  cardDismissed: 'CAC - Dismissed card',
};

export const PARTNERS_EVENTS = {
  OUR_PARTNERS: 'OurPartners',
  CLICKED_TESCO_CLUBCARD_ITEM: 'TescoClubcard',
};

export const TESCO_EVENTS = {
  CLICKED_UPDATE_PAYMENT_METHOD: 'OurPartnersUpdatePaymentMethod',
  CLICKED_SMB: 'OurPartnersSmartMeterBooking',
  CLICKED_TESCO_SIGN_IN: 'OurPartnersTescoSignIn',
  VIEWED_TESCO_PARTNERS_PAGE: 'Viewed Tesco Partners Page',
  CLICKED_TESCO_ACTIVATE_OFFER: 'OurPartnersTescoActivateOffer',
  VIEWED_ACC_HOLDER_ERROR: 'OurPartnersTescoSecondaryAccHolderError',
};

export const CUSTOMER_INFORMATION_CARD_EVENTS = {
  CLICKED_CTA: 'Customer Information Card - clicked CTA',
};
