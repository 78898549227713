import { ChargeAnytimeContextValue } from '../lib/charge-anytime-context';
import { SmartChargers } from '../lib/anytime-chargers';

export const MOCKED_ELIGIBILITY: ChargeAnytimeContextValue['eligibility'] = {
  isEligible: true,
  notSmartMeter: false,
  hasNoInternetAccess: false,
  hasSolarPanels: false,
  incompatibleCar: false,
  numberOfReasons: 0,
  invalidOther: false,
  onlyGasSupply: false,
};

export const MOCKED_CHARGE_ANYTIME_CONTEXT_VALUE: ChargeAnytimeContextValue = {
  smartCharger: SmartChargers.Indra,
  setSmartCharger: () => null,
  make: 'Audi',
  setMake: () => null,
  model: 'A3 Sportback e-tron',
  setModel: () => null,
  year: 2020,
  setYear: () => null,
  hasInternetAccess: true,
  setHasInternetAccess: () => null,
  hasSolarPanels: false,
  setHasSolarPanels: () => null,
  eligibility: MOCKED_ELIGIBILITY,
  isError: false,
  isFetching: false,
  isPayAsYouDriveEnabled: true,
  isPayAsYouDriveVolvoEnabled: false,
  isIndraChargeAnytimeAppEnabled: false,
  isPayAsYouDriveVWGEnabled: false,
  hasSmartMeter: true,
  ineligibleNotSmartMeter: false,
  ineligibleOnlyGas: false,
  ineligibleLoss: false,
  ineligibleOther: false,
  isEligibleVolvo: false,
  isEligibleVWG: false,
  integrationType: 'KaluzaCaApp',
  setVwgOrderNumberError: () => null,
  vwgOrderNumberError: '',
  isPromoCodeError: false,
  setPromoCode: () => null,
  validatePromoCode: () => null,
  validPromoCode: '',
  promoCode: '',
  vwgOrderNumber: '',
  setVwgOrderNumber: () => null,
};

export const mockedChargeAnytimeReturnValue = (
  value: Partial<ChargeAnytimeContextValue> = {}
) => ({
  ...MOCKED_CHARGE_ANYTIME_CONTEXT_VALUE,
  ...value,
});
