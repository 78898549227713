import { Card, P, PrimaryCTALink, Strong } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import { TADO_APPLE_STORE_URL, TADO_GOOGLE_PLAY_URL } from '../constants';
import useWindowDimensions from '@/src/utils/hooks/useWindowDimensions';
import { mqSmallDown, mqSmallUp } from '@/src/utils/mediaQuery';
import GetStartedMobile from '@/src/resources/get-started-with-tado-mobile.png';
import GetStartedLarge from '@/src/resources/get-started-with-tado.png';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { AnalyticsClick } from '@ovotech/ui-tools';

const Container = styled.div<{ backgroundImage: string; mobileImage: string }>`
  position: relative;
  padding: 30px;
  min-height: 207px;
  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    right: 0px;
    top: -1px;
    width: 38%;
    height: 100%;
    background-image: url(${({ backgroundImage }) => backgroundImage});
    background-size: cover;
    background-size: 100% auto;
    background-repeat: no-repeat;
    ${({ mobileImage }) => `
    ${mqSmallDown(`
      width: 100%;
      background-image: url(${mobileImage});
    `)}
  `}
  }
`;

const StyledTadoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  ${mqSmallUp(`
    align-items: center;
`)}
`;

const StyledTadoTextContainer = styled.div`
  flex-basis: auto;
  max-width: 48%;
  ${mqSmallDown(`
    margin-top: 60%;
    max-width: 100%;
  `)}
`;

const StyledButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  ${mqSmallUp(`
    display: none;
  `)}
`;

export const GetStartedWithTado = () => {
  const { isMobileOrTablet } = useWindowDimensions();

  return (
    <Container
      as={Card}
      backgroundImage={GetStartedLarge}
      mobileImage={GetStartedMobile}
    >
      <StyledTadoWrapper>
        <StyledTadoTextContainer>
          <Strong>
            Get started with tado°{isMobileOrTablet ? ' now' : null}
          </Strong>
          {isMobileOrTablet ? (
            <P>
              Create your account and explore the tado° app – smart scheduling,
              room-by-room temperature control, personalised tips and much more!
            </P>
          ) : (
            <P>
              Create your account and explore the tado° app in the{' '}
              <Strong>App Store</Strong> or <Strong>Google Play</Strong> - smart
              scheduling, room-by-room temperature control, personalised tips
              and much more!
            </P>
          )}
        </StyledTadoTextContainer>
      </StyledTadoWrapper>
      <StyledButtonWrapper>
        <AnalyticsClick
          name={
            SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GOT_YOUR_ORDER.CLICKED
              .GO_TO_APP_STORE
          }
        >
          <PrimaryCTALink href={TADO_APPLE_STORE_URL} opensInNewWindow>
            Go to App Store
          </PrimaryCTALink>
        </AnalyticsClick>
        <AnalyticsClick
          name={
            SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GOT_YOUR_ORDER.CLICKED
              .GO_TO_GOOGLE_PLAY
          }
        >
          <PrimaryCTALink href={TADO_GOOGLE_PLAY_URL} opensInNewWindow>
            Go to Google Play
          </PrimaryCTALink>
        </AnalyticsClick>
      </StyledButtonWrapper>
    </Container>
  );
};
