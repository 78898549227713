import { PageWithNavigationAndSectionPadding } from '@/src/components';
import React from 'react';
import * as S from './BatteryBoost.styled';
import { useAccountContext } from '@ovotech/ui-tools';

export const BatteryBoost = () => {
  const { accountId } = useAccountContext();
  const batteryBoostRegisterInterestZohoUrl = `https://survey.zohopublic.eu/zs/TsDXaZ?accountId=${accountId}`;

  return (
    <PageWithNavigationAndSectionPadding title="Battery Boost">
      <S.Iframe title="Zoho form" src={batteryBoostRegisterInterestZohoUrl} />
    </PageWithNavigationAndSectionPadding>
  );
};
