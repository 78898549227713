import {
  CTAButton,
  Heading2,
  Heading3,
  Margin,
  P,
  Small,
  Strong,
  TextLink,
} from '@ovotech/nebula';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom-v5';
import { useTadoEligible } from '../../hooks/use-tado-eligible';
import {
  ErrorSection,
  PageWithNavigation,
  PageWithSimpleHeader,
} from '@/src/components';
import { SMART_THERMOSTATS_STATUS_ERROR } from '@/src/constants/error-section-ids';
import { SMART_THERMOSTATS_MAINTENANCE } from '@/src/constants/feature-flags';
import { ROUTE_SMART_THERMOSTATS_OFFER } from '@/src/constants/routes';
import { LoadingPanel } from '@/src/pages/Payments/DirectDebitCalculator/LoadingPanel';
import { ReactComponent as ConventionalBoiler } from '@/src/resources/tado/conventional-boiler.svg';
import { ReactComponent as CombiBoiler } from '@/src/resources/tado/combi-boiler.svg';
import { useFeature } from '@/src/utils/optimizely';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';
import TadoThermostatImage from '@/src/resources/tado-thermostat.png';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { AnalyticsClick } from '@ovotech/ui-tools';
import { buildPromoCodeLink } from '../../utils/utils';
import * as S from './ComatibilityV2.styled';

export const CompatibilityV2 = () => {
  const eligibility = useTadoEligible();
  const history = useHistory();
  const [isUnderMaintenance] = useFeature(SMART_THERMOSTATS_MAINTENANCE);
  const promoCode = getQueryParamValue('promoCode');

  if (eligibility.isError) {
    return (
      <PageWithNavigation title="Smart Thermostats">
        <ErrorSection
          headingComponent={Heading2}
          id={SMART_THERMOSTATS_STATUS_ERROR}
        />
      </PageWithNavigation>
    );
  }

  if (eligibility.isLoading) {
    return (
      <PageWithNavigation title="Smart Thermostats">
        <LoadingPanel />
      </PageWithNavigation>
    );
  }

  if (!eligibility.isEligible || isUnderMaintenance) {
    return <Redirect to={ROUTE_SMART_THERMOSTATS_OFFER} />;
  }

  const handleBackClick = () => {
    history.push(ROUTE_SMART_THERMOSTATS_OFFER);
  };

  const handleNextStep = () => {
    const route = buildPromoCodeLink(
      ROUTE_SMART_THERMOSTATS_OFFER + '/checkout',
      promoCode,
    );
    history.push(route);
  };

  return (
    <S.StyledContainerWithLongHeaderBg data-testid="compatibility-page-v2">
      <PageWithSimpleHeader
        title="Smart Thermostats"
        analytics={{
          name: SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GET_SET_FOR_TADO
            .VIEWED_V2,
        }}
      >
        <S.StyledHeader>
          <S.StyledHeading1>
            Check if tado° works in your home{' '}
          </S.StyledHeading1>
          <S.StyledImage alt="Tado" src={TadoThermostatImage} />
        </S.StyledHeader>
        <S.Container>
          <S.Content>
            <Margin bottom={4}>
              <Heading3>
                Most of the UK’s gas heating systems are compatible
              </Heading3>
            </Margin>
            <Margin bottom={8}>
              <P>
                tado° isn't linked to a particular manufacturer, which means
                it's compatible with most gas heating systems. This includes the
                UK’s 2 favourites:
              </P>
            </Margin>
            <Margin bottom={8}>
              <S.CardContainer>
                <S.Card>
                  <ConventionalBoiler />
                  <P>Heating systems with a separate hot water tank</P>
                  <Small>Conventional boilers</Small>
                </S.Card>

                <S.Card>
                  <CombiBoiler />
                  <P>Heating systems without a separate hot water tank</P>
                  <Small>Combi boilers</Small>
                </S.Card>
              </S.CardContainer>
            </Margin>
            <Margin bottom={4}>
              <P>
                <Strong>It’s also compatible with:</Strong> Hydronic underfloor
                systems, multi-room systems (with one tado° Smart Thermostat per
                zone), district heating systems, and gravity-fed heating
                systems.
              </P>
            </Margin>

            <P>
              <AnalyticsClick
                name={
                  SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GET_SET_FOR_TADO.CLICKED
                    .TADO_TECH_SUPPORT
                }
              >
                <TextLink
                  href="https://support-request.tado.com/#/contact?locale=en&selection=sales&subselection=compatibility&message="
                  opensInNewWindow
                >
                  Check your specific heating system model and make{' '}
                </TextLink>
              </AnalyticsClick>
            </P>
            <Margin vertical={8}>
              <hr />
            </Margin>
            <P>
              <Strong>
                tado° doesn’t get on with electric systems, including:
              </Strong>{' '}
              Electric radiators, pilot wire (fil pilote), infrared panels
              immersion heaters (hot-water cylinders), and electric underfloor
              heating.
            </P>
          </S.Content>
        </S.Container>
        <S.StyledButtonWrapper>
          <AnalyticsClick
            name={
              SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GET_SET_FOR_TADO.CLICKED
                .BACK
            }
          >
            <CTAButton
              fullWidth="small"
              onClick={handleBackClick}
              variant="secondaryInverted"
            >
              Back
            </CTAButton>
          </AnalyticsClick>
          <AnalyticsClick
            name={
              SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GET_SET_FOR_TADO.CLICKED
                .READY_TO_ORDER
            }
          >
            <CTAButton
              fullWidth="small"
              variant="secondary"
              onClick={handleNextStep}
            >
              Continue
            </CTAButton>
          </AnalyticsClick>
        </S.StyledButtonWrapper>
      </PageWithSimpleHeader>
    </S.StyledContainerWithLongHeaderBg>
  );
};
